<template>
    <div>
        <b-row>
            <b-col>
                <b-card no-body>
                    <b-card-header>
                        <strong>Workshop Setup</strong>
                    </b-card-header>
                    <b-card-body>
                        <b-row>
                            <b-col cols="3">
                                <b-form-group label-for="session-date-range">
                                    <template v-slot:label>
                                        <span class="text-danger">*</span> Session Start Date Within:
                                    </template>
                                    <picker-of-dates id="session-date-range"
                                                     v-model="sessionDateRange"
                                                     format="M/d/yyyy"
                                                     placerholder="Select Date Range"
                                                     :state="fieldState('sessionDateRange')"
                                                     clearable range/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-body>
                    <b-card-footer>
                        <b-button-group size="sm">
                            <b-button class="mr-2" variant="success" @click="doExport">Export</b-button>
                            <b-button variant="info" @click="clearFilters">Clear</b-button>
                        </b-button-group>
                    </b-card-footer>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>


<script>
import { Component, Vue } from 'vue-property-decorator';
import download from '@/util/downloader';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import PickerOfDates from '@/components/shared/PickerOfDates';
import {errorModalOptions} from "../../../util/formatters";


@Component({
    components: {
        PickerOfDates
    }
})
export default class WorkshopSetup extends Vue {

    sessionDateRange = [null,null];

    showState = false;

    fieldState(field) {
        if (field.includes("Filter")) {
            return this.showState ? !!this[field] : undefined; // non-date range field, when exists
        } else {
            return this.showState ? !!this[field][0] : undefined; // PickerOfDates - date range field
        }
    }

    clearFilters() {
        this.sessionDateRange = [null,null];
        this.showState = false;
    }

    async doExport() {
        if (!this.isReady()) {
            return;
        }
        try {
            await download('POST', '/api/v1/export/workshop-setup', JSON.stringify({
                sessionDateRange: this.sessionDateRange
            }));
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    isReady() {
        if (!this.sessionDateRange[0]) {
            this.showState = true;
            return false;
        }
        return true;
    }

    async mounted() {
        this.$store.commit('addBreadcrumb', Breadcrumb.create('Workshop Setup', null, true));
    }
}
</script>


<style scoped>

</style>